
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import TableView from "@views/page/components/table-view.vue";
import FilterModel from "../components/filter-model";
import GetEndpointLogDetailsResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/GetEndpointLogDetailsResponse";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";
import ItemDetale from "@views/page/components/item-detale.vue";

@Options({
  name: "LogsViewDetailPage",
  components: {
    Page,
    TableView,
    ItemDetale,
  },
})
export default class ContentLogsDateailView extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;
  @Prop({ default: null }) slotProps: {
    sourceLogId: Number;
    filterChange: Boolean;
  };
  innerFilter: string;
  item: GetEndpointLogDetailsResponse = null;
  // itemDataLogs: GetEndpointLogDataResponse = null;
  requestId: string = "";
  page: PageRequest = { Take: 20, Skip: 0 };
  count = 0;
  noPagination = false;
  isLoading = false;
  error1 = {
    isSuccess: true,
    errorMessage: "",
  };
  error2 = {
    isSuccess: true,
    errorMessage: "",
  };

  @Watch("filter", { deep: true })
  onFilter() {
    // this.refresh();
  }

  created(): void {
    if (this.$route.params.id) this.requestId = String(this.$route.params.id);
  }

  mounted() {
    this.getEndpointLogDetails();
    // this.getEndpointLogData();
  }

  async getEndpointLogDetails() {
    this.isLoading = true;
    try {
      let response;
      if (this.$route.params?.isActive == "true") {
        response = await this.$api.LogGetterService.getActiveEndpointLogDetailsAsync(
          `"${this.requestId}"`
        );
      } else {
        response = await this.$api.LogGetterService.getEndpointLogDetailsAsync(
          `"${this.requestId}"`
        );
      }
      if (response) {
        this.item = response;
      }
    } catch (error) {
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  // async getEndpointLogData() {
  //   this.isLoading = true;
  //   try {
  //     let response = await this.$api.LogGetterService.getEndpointLogDataAsync(
  //         `"${this.requestId}"`
  //     );
  //     if (response) {
  //       this.itemDataLogs = response;
  //       console.log(this.itemDataLogs, "itemDataLogs");
  //     }
  //   } catch (error) {
  //     this.error2.isSuccess = false;
  //     this.error2.errorMessage = error;
  //     this.isLoading = false;
  //   }
  //   this.isLoading = false;
  // }

  //событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.page.Take;
    if (this.page.Skip === skip) {
      return;
    }
    this.page.Skip = skip;
    this.getEndpointLogDetails();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  onReadMore(id: string) {
    console.log(`id=`, id);
  }
}
